import React, { useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import ArrowDown from '../svg/arrow-down.svg';

export default function Accordion(props) {
  const [open, setOpen] = useState(props.open);

  useLayoutEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useLayoutEffect(() => {
    if (props.setOpen) {
      props.setOpen(open);
    }
  }, [open]);

  return (
    <div
      className={props.className}
      css={css`
        position: relative;
        border-top: 2px solid var(--black);
      `}
    >
      <div
        className={(props.small) ? 'type-45' : 'type--70'}
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: ${(props.small) ? 'calc(var(--gutter) * 0.5) 0 calc(var(--gutter) * 0.25)' : 'var(--gutter) 0'};
          cursor: pointer;
          transition: color 0.6s var(--curve);

          &:hover {
            color: var(--primary);
            transition: color 0s;

            svg path {
              stroke: var(--primary);
              transition: stroke 0s;
            }
          }
        `}
        role="button"
        aria-expanded={(open) ? true : false}
        tabIndex="0"
        onClick={() => setOpen(!open)}
        onKeyPress={(e) => {
          e.persist();

          if (e.key === 'Enter')
            setOpen(!open);
        }}
      >
        <div
          css={css`
            position: relative;
            top: ${(props.small) ? '0' : '0.5rem'};

            @media (max-width: 700px) {
              position: relative;
              top: ${(props.small) ? '0' : '0.5rem'};
            }
          `}
        >{props.title}</div>
        <div
          css={css`
            svg {
              position: relative;
              top: 0;
              width: auto;
              height: ${(props.small) ? '3rem' : '4.8rem'};
              transform: rotate(${(open) ? '-180' : 0}deg);
              transition: transform 0.6s var(--curve);

              @media (max-width: 700px) {
                position: relative;
                top: ${(props.small) ? '0' : '0.5rem'};
                height: ${(props.small) ? '2.5rem' : '4rem'};
              }

              path {
                transition: stroke 0.6s var(--curve);
              }
            }
          `}
        >
          <ArrowDown />
        </div>
      </div>
      <SlideDown
        className="type--45"
        css={css`
          transition: height 0.6s var(--curve);
        `}
        closed={!open}
      >
        <div
          css={css`
            padding-bottom: ${(props.noPadding) ? 'var(--gutter)' : 'var(--margin)'};
          `}
        >{props.children}</div>
      </SlideDown>
    </div>
  )
}
